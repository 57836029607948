/* all Banner */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root{
  --gradient-color-area:linear-gradient(90deg, #25AADD 0%, #005AFF 100%);
}
.homebanner {
  margin-top: 100px;
  width: 100%;
  /* height: 10%; */
}

.homeBannerParent {
  position: relative;
}
.imageBanner {
  width: 100%;
  height: 100%;
}

.homeBannerParent::before {
  text-shadow: 0px 0px 3px #fff;
  content: "Big Thoughts Bigger Actions & Greater Results";
  position: absolute;
  color: white;
  z-index: 99;
  text-transform: uppercase;
  top: 50%;
  /* left: 20px; */
  padding-left: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 55px;
  font-weight: 700;
  /* width: 100vw; */
  /* background-color: #6366f1; */
  width: 100%;
}

.homeServicesParent {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}


.card-hover-effect{
  border-radius: 30px !important;
}



/* All banner media quary*/
@media only screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .homebanner {
    min-height: 25vh;

  }
}

@media only screen and (min-device-width: 426px) and (max-device-width: 768px) {
  .homebanner {
    min-height: 40vh;

  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1441px) {
  .homebanner {
    /* min-height: 25vh; */
  }
}

/* Web Development */
/* media */

/* Mission and vision desktop size */
@media only screen and (min-device-width: 900px)  {
    .missionVisionBanner {
    margin: 70px;
  }
}


/* Our Development Process */
/*768px*/
@media only screen and (min-device-width: 375px) and (max-device-width: 767px) {
  .webcont1 {
    margin-top: -100px;
  }
}

@media only screen and (min-device-width: 250px) and (max-device-width: 374px) {
  .webcont1 {
    margin-top: -130px;
  }
}

/* Carrers media quary*/
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .Carrergif {
    padding-top: 75px;
  }
}

@media screen and (max-width: 460px) {
  .Carrergif {
    padding-top: 60px;
  }
}

/* Client media quary*/
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .clientcard1 {
    height: 550px;
  }
  .btn3 {
    padding-top: 75px;
  }
  .btn2 {
    padding-top: 50px;
  }
  .btn1 {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .clientcard1 {
    height: 550px;
  }
  .btn3 {
    padding-top: 20px;
  }
  .btn2 {
    padding-top: 45px;
  }
  .btn1 {
    padding-top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .clientcard1 {
    height: 500px;
  }
  .btn4 {
    padding-top: 10px;
  }
  .btn3 {
    padding-top: 30px;
  }
  .btn2 {
    padding-top: 45px;
  }
  .btn1 {
    padding-top: 20px;
  }
}

@media screen and (min-width: 557px) and (max-width: 767px) {
  .clientcard1 {
    height: 550px;
  }
  .btn4 {
    padding-top: 10px;
  }
  .btn3 {
    padding-top: 35px;
  }
  .btn2 {
    padding-top: 45px;
  }
}

@media screen and (min-width: 378px) and (max-width: 555px) {
  .clientcard1 {
    height: 450px;
  }
  .btn4 {
    padding-bottom: 10px;
  }
  .btn3 {
    padding-bottom: 10px;
  }
  .btn2 {
    padding-bottom: 10px;
  }
  .btn1 {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 300px) and (max-width: 377px) {
  .clientcard1 {
    height: 480px;
  }
  .btn4 {
    padding-bottom: 10px;
  }
  .btn3 {
    padding-bottom: 10px;
  }
  .btn2 {
    padding-bottom: 10px;
  }
  .btn1 {
    padding-bottom: 10px;
  }
}

/* About us */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .webbtn {
    padding-top: 25px;
  }
  .Addbtn {
    padding-top: 45px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1198px) {
  .Addbtn {
    padding-top: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Addbtn {
    padding-top: 180px;
  }
  .webbtn {
    padding-top: 10px;
  }
  .databtn {
    padding-top: 58px;
  }
  .cloudbtn {
    padding-top: 12px;
  }
}

/* Cloud */
@media screen and (min-width: 300px) and (max-width: 355px) {
  .Cloudimage {
    width: 100px;
  }
}

/* Industry media quary */
@media screen and (min-width: 900px) {
  .indbank {
    padding-bottom: 33px;
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 427px) {
 

  /* cloud  private*/

  .cloudmanage {
    padding-top: 25px;
  }
}

/* web devlopment media quary */
/* gif video */
@media screen and (max-width: 768px) {
  .homeServicesParent {
    grid-template-columns: 1fr;
  }

  .homeServicesImg {
    grid-row: 1;
  }

  .missionVisionBanner {
    margin: 5px;
  }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
  .area {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {

  

  :root {
    --selectbtnfntsize: 13px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 999px) {
  .webvideoplay {
    padding-top: 20px;
  }
}

@media screen and (min-width: 427px) and (max-width: 767px) {
  .webvideoplay {
    margin-top: -70px;
  }

  /* cloud  private*/

  .cloudmanage {
    padding-top: 30px;
  }
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .webvideoplay {
    margin-top: -70px;
  }
  .homesumbmit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 150px) and (max-width: 299px) {
  .webvideoplay {
    margin-top: -70px;
  }
}

@media screen and (min-width: 318px) and (max-width: 373px) {
  .contactbox {
    margin-left: -10%;
  }
}

.area-left,
.area-right {
  flex: 1;
  padding: 8px;
  cursor: pointer;
}

.active-link-css {
  background-color: #f0f0f0; /* Example active link styling */
}

@media (max-width: 767px) {
  .area-left,
  .area-right {
    flex-basis: 100%;
    text-align: center;
  }
}

.main-section {
  margin-top: 70px;
  margin-bottom: 50px;
}

:root {
  --common-padding: 12px 17px;
  --common-transition: 0.2s;
  --selectbtnfntsize: 18px;
}

.area {
  display: flex;
  /* background-color: #e0e0e0; */
  background-color: #1976d921;
  flex-wrap: wrap;
  margin: 16px;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  font-size: var(--selectbtnfntsize);
  cursor: pointer;
  color: black;
}
.area-right {
  padding: var(--common-padding);
  transition: var(--common-transition);
  border-radius: inherit;
  text-align: center;
}
.area-left {
  padding: var(--common-padding);
  transition: var(--common-transition);
  border-radius: inherit;
  text-align: center;
}
.active-link-css {
  background: var(--gradient-color-area);
  color: white;
  text-align: center;
  margin: 5px;
}

.noDataFoundImage {
  height: 50%;
  width: 50%;
}
.costum-btn-size {
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem;
  margin: 5px 5px;
}
.ManagerEmployeeShiftDia {
  width: 55%;
}
.mydoxwidthcntrl {
  width: 100%;
}
.mydoxwidthcntrl table {
  width: 100%;
}


@keyframes shrinkColorfulLine {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 0% 100%;
  }
}

.colorful-line {
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  background-size: 100% 100%;
  animation: shrinkColorfulLine 10s forwards;
}

.thankyouimage{
  width: 100%;
  height: calc(100vh - 100px);

}


.dropdown-panel-custom {
    border: 1px solid #6d6969;
    /* background-color: #080808; */
    box-shadow: none;
    padding: 10px;
    /* Add any additional styling as needed */
}
.p-dropdown-label{
width: 100%;
}
.p-dropdown-item {
    padding: 8px;
    /* Custom hover style */
}

.p-dropdown-item:hover {
    /* background-color: #222121; */
}
