:root {
  --gradient-color: -webkit-linear-gradient(#005aff, #25aadd);
}
.liststyle li {
  list-style-type: none;
}
.costom-clota-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 1rem;
}

.card-hover-effect:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
}
.about-card-radious {
  border-radius: 20px;
}
.Text-Gradient {
  background: var(--gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.databaseservices .Text-Gradient {
  font-weight: 100;
}

.Home-Form .p-dropdown {
  width: 100%;
}
.ReachDirectly a {
  color: #25aadd;
  font-weight: 600;
}
.missionVisionBanner-icon {
  font-size: 2rem;
  margin: 0 11px;
  color: #005aff;
}

.missionVisionBanner img {
  height: 250px;
  width: 100%;
  border-radius: 20px;
}
.btn-styles {
  color: white !important;
  background: var(--gradient-color-area);
}
.homeServicesImg .cloud-srvcs {
  /* width: 100%; */
  height: 300px;
}
.homeServicesImg {
  text-align: center;
}
.homeServicesImg img {
  border-radius: 10px;
}
.contact-icon {
  font-size: 2rem;
  color: #005aff;
}
.industryimage1 {
  margin-top: 20px;
  height: 300px;
  width: 65%;
}
.AdditionalSrvcsHeading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  text-align: center;
  padding: 1vw;
}
#footer {
  background-color: #eeeeee;
  margin-top: 20px;
}
#footer ul {
  padding: 0;
}
.homebannerImg img {
  margin-top: 20px;
  width: 100%;
  height: 28.98rem;
}

.features-points {
  display: grid;
  grid-template-columns: 39px auto;
  align-items: center;
  
}
.features-points {
  line-height: 3.5rem;
}

.features-points svg {
  font-size: 1.8rem;
  color: rgb(50, 0, 111);
  margin-top: -13px;
  /* margin-left: 10px; */
}

/*HelloEMS features */

.helloEMSParent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.helloEMSParent img {
  width: 40%;
  height: 300px;
}

/* From Uiverse.io by joe-watson-sbf */
.helloEmsCard {
  width: 100px;
  height: 80px;
  transition: all 0.5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
    -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
}

.helloEmsCard:hover {
  height: 200px;
  background: linear-gradient(360deg, #edededc5 60%, hsla(0, 0%, 13%, 1) 70%);
}

.helloEmsCard .header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #212121;
  margin-bottom: 16px;
}

.helloEmsCard .header .img-box {
  width: 50px;
}

.helloEmsCard .header .title {
  font-size: 1em;
  letter-spacing: 0.1em;
  font-weight: 900;
  text-transform: uppercase;
  padding: 4px 0 14px 0;
  transition: all 0.5s;
  color: #edededc5;
}

.helloEmsCard:hover .header {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.helloEmsCard:hover .helloEmsCard .header .title {
  padding: 0;
}

.helloEmsCard .content {
  display: block;
  text-align: left;
  color: #212121;
  margin: 0 18px;
}

.helloEmsCard .content p {
  transition: all 0.5s;
  font-size: 0.8em;
  margin-bottom: 8px;
}

.helloEmsCard .content a {
  color: #1d8122;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
}

.helloEmsCard .content .btn-link:hover {
  border-bottom: 1px solid #1d8122;
}
/*HelloEMS features */

@media screen and (max-width: 600px) {
  .homeServicesImg .cloud-srvcs {
    height: 200px;
  }
  .industryimage1 {
    height: 160px;
  }
  .AdditionalSrvcsHeading {
    font-size: 1rem;
  }

  .homebannerImg img {
    height: 100%;
    width: 100%;
  }

  .helloEMSParent {
    display: block;
    justify-content: center;
    align-items: center;
  }

  .helloEMSParent img {
    width: 100%;
    height: 40%;
  }

  .features-points {
  line-height: 2rem;
  margin-left: 50px;
}
}
